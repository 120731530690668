import React from 'react';
import AppStoreDownload from '../AppStoreDownload/AppStoreDownload';
import './Demo.scss';

const DemoComponent = ({ name }) => (
  <section className="demo-section">
    <h1>
      <span style={{ letterSpacing: 15 }}>👋</span>Hi, {name ?? 'there'}
    </h1>
    <div className="demo-live-container">
      <h2>
        <span style={{ letterSpacing: 15 }}>📲</span>You can try a live demo on
        the app
      </h2>
      <AppStoreDownload></AppStoreDownload>
    </div>
    <div className="demo-walkthrough">
      <h2>
        <span style={{ letterSpacing: 15 }}>🪂</span>Or watch us do a walkthrough
      </h2>
      <iframe className="walkthrough-iframe" src="https://www.youtube-nocookie.com/embed/YB3v51SWDz0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
    </div>
    <div className="demo-video-container">
      <h2>
        <span style={{ letterSpacing: 15 }}>🎥</span>Or check out this video
      </h2>
      <video
        autoPlay={true}
        playsInline={true}
        loop={true}
        muted={true}
        className="demo-video"
      >
        <source
          src="https://storage.googleapis.com/mosaicmoney-assets/preview.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </section>
);

export default DemoComponent;
