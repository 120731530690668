import React from 'react';
import { Router } from "@reach/router";
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Demo from '../components/Demo';

const DemoPage = () => (
  <Layout pageTitle={'Demo'} hideFooter>
    <SEO title={'Demo'} />
    <Router>
      <Demo path="/demo"></Demo>
      <Demo path="/demo/:name"></Demo>
      <Demo path="/demo/:name/*"></Demo>
    </Router>
  </Layout>

)

export default DemoPage;